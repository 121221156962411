import { useEffect, useState } from "react";
import { monthList } from "../../Common/contants";
import FieldCreater from "./Form/FieldCreater";
import axios from "axios";

function MrMonthComponent({ mr, setMr, month, setMonth, role, setMrDetails }) {
  const [mrs, setMrs] = useState([]);
  useEffect(() => {
    axios
      .get(`https://alfamr.ovinfinity.com/get.php?name=mrs`)
      .then((res) => {
        // console.log(res.data);
        setMrs(res.data ?? []);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div style={{ display: "flex", gap: 16 }}>
      {role === "admin" && (
        <FieldCreater
          headerName={"Select MR"}
          onChangeHandler={(e, v) => {
            setMr(e.target.value);
            setMrDetails(mrs.find((k) => k.id === e.target.value));
          }}
          value={mr}
          type="list"
          formLayoutAttr={{
            table: "mrs",
            tableKey: "mrName",
          }}
        />
      )}
      <FieldCreater
        headerName={"From"}
        onChangeHandler={(e) => {
          setMonth(
            (prev) =>
              `'${e.target.value}' and ${prev ? prev.split(" and ")[1] : ""}`
          );
        }}
        value={month ? month.split(" and ")[0].replaceAll("'", "") : ""}
        type="date"
        formLayoutAttr={{
          type: "date",
        }}
      />
      <FieldCreater
        headerName={"To"}
        onChangeHandler={(e) => {
          setMonth(
            (prev) =>
              `${prev ? prev.split(" and ")[0] : ""} and '${e.target.value}'`
          );
        }}
        value={month ? month.split(" and ")[1].replaceAll("'", "") : ""}
        type="date"
        formLayoutAttr={{
          type: "date",
        }}
      />

      {/* <FieldCreater
        value={month}
        field="month"
        headerName={"Month"}
        type="list"
        onChangeHandler={(e) => setMonth(e.target.value)}
        formLayoutAttr={{
          type: "list",
          tableKey: "value",
          values: Object.keys(monthList).map((key) => ({
            id: monthList[key].key,
            value: key,
          })),
        }}
      /> */}
    </div>
  );
}

export default MrMonthComponent;
